import { graphql } from 'gatsby'
import ComparisonPage from '../containers/Comparison'

export default ComparisonPage

export const pageQuery = graphql`
  query ComparisonPageQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $categoryId: String
    $tagsIds: [String]
    $hasTags: Boolean!
  ) {
    comparison: comparison(id: { eq: $id }) {
      ...ComparisonInformation
      ...ComparisonThumbnailRegular
    }

    # Related comparisons based on tags and category
    tagCategoryComparisons: allComparison(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        tags: { elemMatch: { id: { in: $tagsIds } } }
        category: { id: { eq: $categoryId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) @include(if: $hasTags) {
      nodes {
        ...ComparisonPreview
        ...ComparisonThumbnailRegular
      }
    }

    # Related comparisons based on tags only
    tagComparisons: allComparison(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        tags: { elemMatch: { id: { in: $tagsIds } } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) @include(if: $hasTags) {
      nodes {
        ...ComparisonPreview
        ...ComparisonThumbnailRegular
      }
    }

    # Related comparisons based on category only
    categoryComparisons: allComparison(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        category: { id: { eq: $categoryId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ComparisonPreview
        ...ComparisonThumbnailRegular
      }
    }

    previous: comparison(id: { eq: $previousId }) {
      id
      slug
      title
    }

    next: comparison(id: { eq: $nextId }) {
      id
      slug
      title
    }
  }
`
